import { defineNuxtPlugin } from '#app'
import {googleTrackJoinKlaviyoEmailList} from "~/utils/google-track";
import {metaPixelTrackKlaviyoSignUp} from "~/utils/meta-pixel";

export default defineNuxtPlugin(() => {
  if (process.client) {
    window.addEventListener("klaviyoForms", function(e) {
      if (e.detail.type === 'open') {
        // Custom JS
        console.log("klaviyoForms opened")
      }
    });

    window.addEventListener("klaviyoForms", function(e) {
      if (e.detail.type === 'submit') {
        console.log("klaviyoForms submitted")
        metaPixelTrackKlaviyoSignUp()
        const email = e.detail.data?.email; // 获取 email
        googleTrackJoinKlaviyoEmailList(email)
      }
    });
  }
});
