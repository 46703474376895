import MD5 from 'crypto-js/md5';

export function metaPixelTrackCompleteRegistration (email) {
  window.fbq('track', 'CompleteRegistration', {
    currency: null,
    value: null
  }, {
    eventID: MD5(email).toString() // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}


export function metaPixelTrackSubscribeIntent({orderId, totalBilled}) {
  window.fbq('track', 'SubscribeIntent', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackResubscribeIntent({orderId, totalBilled}) {
  window.fbq('track', 'ResubscribeIntent', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackResumeSubscriptionIntent({orderId, totalBilled}) {
  window.fbq('track', 'ResumeSubscriptionIntent', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackUnlockEarlyIntent({orderId, totalBilled}) {
  window.fbq('track', 'UnlockEarlyIntent', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}


export function metaPixelTrackSubscribe({orderId, totalBilled}) {
  window.fbq('track', 'Subscribe', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackResubscribe({orderId, totalBilled}) {
  window.fbq('track', 'Resubscribe', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackResumeSubscription({orderId, totalBilled}) {
  window.fbq('track', 'ResumeSubscription', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackUnlockEarly({orderId, totalBilled}) {
  window.fbq('track', 'UnlockEarly', {
    currency: "USD",
    predicted_ltv: null,
    value: totalBilled,
    orderId
  }, {
    eventID: orderId // 处理重复的 Pixel 像素代码和转化 API 事件 https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
  });
}

export function metaPixelTrackKlaviyoSignUp() {
  // Meta Pixel 埋点 BNTO-1482
  window.fbq('track', 'CompleteKlaviyoBNTOSignUp', {
    content_name: 'Klaviyo BNTO Sign Up Form', // 广告位的名称或标识
    content_type: 'ad',
    ad_position: window.location.href, // 你可以自定义的位置标识
    click_action: 'complete_Klaviyo_BNTO_Sign_Up' // 描述点击的动作
  });
}
