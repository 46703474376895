import { useGtm } from '@gtm-support/vue-gtm';
import MD5 from 'crypto-js/md5';
import {useBaseStore} from "~/stores/base";
import {useSubscribeInfoStore} from "~/stores/subscribe";

// docs https://docs.google.com/spreadsheets/d/1JJrqWvG6kChhzFmATpwbXXYnUBIwhRyIwBGm-PcnYRE/edit?gid=0#gid=0

const generateRegisteredUserAttributes = () => {
  const isLogin = useBaseStore().getterIsLogin
  const shippingAddress = useSubscribeInfoStore().getterShippingAddress
  const userInfo = useBaseStore().getterUserInfo
  if (!isLogin) return {}
  return {
    user_first_name: userInfo.name.split(" ")[0] || shippingAddress.firstName,
    user_last_name: userInfo.name.split(" ")[1] || shippingAddress.lastName,
    user_email: userInfo.email,
    user_zip: shippingAddress.zip,
    user_email_md5: MD5(userInfo.email).toString(),
    user_phone: userInfo.mobile || shippingAddress.mobile,
    user_id: userInfo.userId
  }
}

export function googleTrackPageView(screenName, currentPath) {
  try {
    useGtm().trackView(screenName, currentPath, {
      attributes: {
        ...generateRegisteredUserAttributes()
      }
    });
  } catch (error) {
    console.error("Google Tag Manager Error: ", error);
  }
}

export function googleTrackSignUp(email) {
  // GTM
  useGtm().trackEvent({
    event: "signUp",
    category: 'User Registration',
    action: 'Create Account',
    label: "Registration Page",
    value: MD5(email).toString(),
    attributes: {
      ...generateRegisteredUserAttributes()
    }
  });
}

export function googleTrackSubscribe(orderId, totalBilled) {
  // GTM
  useGtm().trackEvent({
    event: "subscribe",
    category: 'Subscription',
    action: 'Subscribe to BNTO',
    label: "First Subscription",
    value: totalBilled,
    attributes: {
      subscription_price: totalBilled,
      currency_code: "USD",
      subscription_id: orderId,
      ...generateRegisteredUserAttributes()
    }
  });
}

export function googleTrackJoinKlaviyoEmailList(email) {
  // GTM
  useGtm().trackEvent({
    event: "submitLead",
    category: 'Email List',
    action: 'Join List',
    label: "Klaviyo",
    attributes: {
      lead_email: email,
      lead_email_md5: MD5(email).toString(),
      ...generateRegisteredUserAttributes()
    }
  });
}

export function googleTrackViewProduct(product) {
  // GTM
  useGtm().trackEvent({
    event: "viewProduct",
    category: "ecommerce",
    action: "view",
    label: product.title,  // 产品名称
    value: product.shopProductId,  // 产品价格（可选）,
    attributes: {
      product_category: JSON.stringify(product.categoryList),
      product_price: product.price,
      product_id: product.shopProductId,
      product_name: product.title,
      product_image_url: product.optionConfig[0].imageSets[0].url,
      ...generateRegisteredUserAttributes()
    }
  })
}
