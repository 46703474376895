import { defineNuxtPlugin } from '#app'
import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  // GTM
  nuxtApp.vueApp.use(
    createGtm({
      id: 'GTM-MS45NNNM', // 替换为你的 GTM 容器 ID
      vueRouter: nuxtApp.$router, // 绑定 Nuxt 路由
      enabled: process.env.NODE_ENV === "production", // 仅在生产环境启用
      debug: process.env.NODE_ENV !== "production", // 开发环境启用调试
    }),
  )
})
