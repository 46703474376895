import { defineNuxtPlugin } from '#app';
import _ from 'lodash';
import { useBaseStore } from "~/stores/base";
import chalk from "chalk";

function setLocation() {
  if (!navigator.geolocation) {
    console.log("NOT_SUPPORT");
    return;
  }

  if (location.protocol !== 'https:') {
    console.log(chalk.red('Geolocation is only supported in HTTPS.'));
    return;
  }

  console.log("Attempting to get location...");
  navigator.geolocation.getCurrentPosition(
    position => setPosition(position),
    error => showError(error)
  );
}

async function setPosition(position) {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  const positionStr = `LNG=${longitude};LAT=${latitude}`;
  console.log(chalk.greenBright('[Location Updated]'), positionStr);
  useBaseStore().updateXBntoLocation(positionStr);
}

function showError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log(chalk.red("User denied the request for Geolocation."));
      break;
    case error.POSITION_UNAVAILABLE:
      console.log(chalk.red("Location information is unavailable."));
      break;
    case error.TIMEOUT:
      console.log(chalk.red("The request to get user location timed out."));
      break;
    case error.UNKNOWN_ERROR:
      console.log(chalk.red("An unknown error occurred while trying to get location."));
      break;
  }
}

export default defineNuxtPlugin(() => {
  if (process.client) {
    const location = useBaseStore().getterXBntoLocation;
    if (_.isEmpty(location)) setLocation();
  }
});
