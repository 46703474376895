import {defineNuxtPlugin} from '#app';
import {useBaseStore} from "~/stores/base";
import {appInit} from "~/api/api.app";
import Cookies from "js-cookie";

function analyseAndSetCookieRefererTracking() {
  if (!document.referrer || document.referrer.includes("bnto.rent")) return // 来源是本站的（用户直接浏览器输入地址访问的）不计入
  const currentVisitRefer = {
    rf: document.referrer || '',
    lpu: useRoute().fullPath,
    ca: new Date().getTime(),
    utm_source: useRoute().query.utm_source || "",
    utm_medium: useRoute().query.utm_medium || "",
    utm_campaign: useRoute().query.utm_campaign || "",
    utm_term: useRoute().query.utm_term || "",
    utm_content: useRoute().query.utm_content || ""
  }
  const cookieRt = Cookies.get("rt")
  let rt = {src: []}
  if (!cookieRt) {
    rt.src.push(currentVisitRefer)
  } else {
    let src = JSON.parse(cookieRt).src
    src.push(currentVisitRefer)
    rt.src = src.slice(-3) // 仅保留最后三项
  }
  Cookies.set("rt", JSON.stringify(rt), {
    path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
  })
}

export default defineNuxtPlugin(async () => {
  const route = useRoute()
  const source = route.query.source
  if (source) useBaseStore().updateXBntoSource(source)

  try {
    const initRes = await appInit()
    useBaseStore().updateAppInit(initRes.result)
    console.log("[init]", initRes.result)
  } catch (e) {
    console.error("[init]", e)
  }

  if (process.client) {
    analyseAndSetCookieRefererTracking()

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    console.log(`Screen Resolution: ${screenWidth}x${screenHeight}`);
    Cookies.set("resolution", `${screenWidth}x${screenHeight}`, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });

    if (navigator.connection) {
      // 如果支持 navigator.connection，则获取网络类型
      const connectionType = navigator.connection.effectiveType;
      console.log(`Network Type: ${connectionType}`);

      // 设置 Cookie 存储网络类型
      Cookies.set("network", connectionType, {
        path: "/",
        domain: ".bnto.rent",
        expires: 365 // 365 天后过期
      });
    } else {
      console.log('Network information is not supported in this browser.');

      // 检查是否在线
      const onlineStatus = navigator.onLine ? "online" : "offline";
      console.log(`Online Status: ${onlineStatus}`);

      // 如果处于在线状态，假设网络类型为 "unknown"，否则为 "offline"
      const fallbackNetworkType = navigator.onLine ? "unknown" : "offline";

      // 设置 Cookie 存储网络类型或在线状态
      Cookies.set("network", fallbackNetworkType, {
        path: "/",
        domain: ".bnto.rent",
        expires: 365 // 365 天后过期
      });
    }

    const language = navigator.language || navigator.userLanguage;
    console.log(`Device Language: ${language}`);
    Cookies.set("language", language, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });

    const deviceId = useBaseStore().getterDeviceId
    console.log(`deviceId: ${deviceId}`);
    Cookies.set("deviceId", deviceId, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });
  }
});
